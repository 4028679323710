import React from 'react';
import { clsx } from 'clsx';

import styles from './button-core.module.css';

interface ButtonCoreProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    view?: 'primary' | 'secondary' | 'accent' | 'outline' | 'ghost' | 'link';
    size?: 'small' | 'large';
    className?: string;
    rightAddon?: React.ReactNode;
    leftAddon?: React.ReactNode;
}

export const ButtonCore = React.forwardRef<HTMLButtonElement, ButtonCoreProps>(
    ({ children, onClick, className, size, view, rightAddon, leftAddon, ...props }, ref) => {
        return (
            <button
                ref={ref}
                className={clsx(styles.button, className, {
                    [styles.primary]: view === 'primary',
                    [styles.secondary]: view === 'secondary',
                    [styles.accent]: view === 'accent',
                    [styles.outline]: view === 'outline',
                    [styles.ghost]: view === 'ghost',
                    [styles.link]: view === 'link',
                    [styles.large]: size === 'large',
                    [styles.small]: size === 'small',
                    [styles['right-paddings']]: !!rightAddon,
                    [styles['left-paddings']]: !!leftAddon,
                })}
                onClick={onClick}
                {...props}
            >
                {leftAddon ? <div>{leftAddon}</div> : null}
                {children}
                {rightAddon ? <div>{rightAddon}</div> : null}
            </button>
        );
    },
);

ButtonCore.displayName = 'ButtonCore';
