'use client';

import Image from 'next/image';
import { useRouter } from 'next/navigation';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import './global.css';
import '../styles/variables.css';
import styles from './not-found.module.css';

export default function NotFound() {
    const router = useRouter();

    return (
        <div className={styles['container']}>
            <Image src={'/not-found.png'} alt={'not-found'} width={470} height={430} priority />
            <TypographyCore variant='h2' className={`${styles['header']} mb-20 mt-20`}>
                ой, какая-то ошибка 404
            </TypographyCore>
            <div className={styles['text-container']}>
                <TypographyCore view='medium' color='secondary' size='l' className='mb-48'>
                    а это значит, что Вы зашли туда, куда еще никто не заходил... Даже мы...
                </TypographyCore>
                <TypographyCore view='medium' color='secondary' size='l' className='mb-32'>
                    Давайте поскорее уйдем с этой страницы и пойдем радовать наших близких
                </TypographyCore>
                <ButtonCore
                    view='secondary'
                    size='large'
                    onClick={() => {
                        router.push('/catalog/');
                    }}
                >
                    Каталог
                </ButtonCore>
            </div>
        </div>
    );
}
