import React, { ReactNode } from 'react';
import { useMediaQuery } from '@chakra-ui/react';

import { sfProText } from '../../../app/fonts';

import styles from './typography-core.module.css';

type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';

interface TypographyCoreProps {
    variant?: TypographyVariant;
    children: ReactNode;
    className?: string;
    size?: 'l' | 'm' | 's' | 'xs';
    view?: 'bold' | 'regular' | 'medium';
    color?: 'primary' | 'secondary' | 'light' | 'heavy' | 'inverse' | 'green-brand' | 'orange-brand';
    onClick?: () => void;
    useSeparate?: boolean;
}

export const TypographyCore = ({ variant = 'p', children, className, size, view, color, onClick, useSeparate = false }: TypographyCoreProps) => {
    const Component = variant;
    const formatNumber = (string: ReactNode) => {
        return string?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });
    return (
        <Component
            className={`${styles[variant]} ${size ? styles[size] : ''} ${view ? styles[view] : ''} ${color ? styles[color] : ''} ${className} ${isMobile ? sfProText.className : ''}`}
            onClick={onClick}
        >
            {useSeparate ? <>{formatNumber(children)}</> : children}
        </Component>
    );
};
